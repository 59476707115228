<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :isLoading="isLoadingPage"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    @save="onSave('TariffCalls')"
    @cancel="onCancel('TariffCalls')"
  />
</template>

<script>
// mixins
import editableListItemCreate from '@/mixins/editableListItemCreate.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'TariffCallCreate',

  components: {
    SmEditableItem,
  },

  mixins: [editableListItemCreate],

  data() {
    return {
      isLoadingPage: false,
      controllerName: 'TariffCalls',
      pageHeader: 'Создание тарифа на звонки',
      breadcrumbs: [
        {
          text: 'Клиенты',
          route: { name: 'CustomersMain' },
        },
        {
          text: 'Тарифы на звонки',
          route: { name: 'TariffCalls' },
        },
        {
          text: 'Создание тарифа на звонки',
        },
      ],
      initialForm: {
        customerID: null,
        tariff: null
      },
      form: null,
    };
  },

  computed: {
    ...mapState({
      customers: (state) => state.common.customers
    }),
    fields() {
      return [
        {
          form: [
            {
              type: 'select',
              key: 'customerID',
              label: 'Клиент',
              list: this.customers.data
            },
            {
              type: 'decimalNumber',
              key: 'tariff',
              label: 'Тариф',
            },
          ]
        },
      ];
    },
  },

  created() {
    this.isLoadingPage = true;
    this.getCommonList({ name: 'Customers' })
    .then(() => {
      this.isLoadingPage = false;
    });
  },

  methods: {
    ...mapActions({
      createItem: 'editableList/createItem',
      getCommonList: 'common/getCommonList',
    }),
  },
};
</script>
